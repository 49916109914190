/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

@import url('https://fonts.cdnfonts.com/css/brittany-signature');

@tailwind base;
@tailwind components;
@tailwind utilities;


/* src/index.css */



.bg-centered-gradient {
  position: relative;
}

.bg-centered-gradient::before {
  content: "";
  position: absolute;
  top: 15%;
  left: 45%;
  transform: translate(-50%, -30%);
  width: 700px; /* Adjust the size as needed */
  height: 700px; /* Adjust the size as needed */
  background: radial-gradient(circle at center, #ffbbbb, #ffedb7);
  filter: blur(80px);
  opacity: 0.8;
  z-index: -1;
}

/* Keyframes for Option 2 */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Add these utility classes for quick background changes */
.bg-noise {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  opacity: 0.05;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
